import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';


@Injectable()
export class MaestroRequisitosService {
  static ENDPOINT: string = '/api/maestroRequisitos/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }

  public getAll(): Observable<any> {
    return this._http
               .get(MaestroRequisitosService.ENDPOINT.replace(':id', ''));
  }
}