import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { MetodoPago } from "./class-metodoPago";


@Injectable()
export class MetodoPagoService {
  static ENDPOINT: string = '/api/metodoPagos/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }
  public getAll():Observable<any> {
    return this._http
               .get(MetodoPagoService.ENDPOINT.replace(':id', ''));
  }


  public getById(id: string):Observable<any> {
    return this._http
               .get(MetodoPagoService.ENDPOINT.replace(':id',`${id}`));
  }  

  public getByMetodoPagoId(metodoPagoId: string):Observable<any> {
    return this._http
               .get(MetodoPagoService.ENDPOINT.replace(':id',`${metodoPagoId}`));
  } 
  
  public getByPaisId(id: string):Observable<any> {
    return this._http
               .get(MetodoPagoService.ENDPOINT.replace(':id',`/pais/${id}`));
  }  

  public update(entidad:MetodoPago):Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);  
     let id= entidad.Id.toString();      

    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');    

    return this._http
               .put(MetodoPagoService.ENDPOINT.replace(':id', id), entidad, {headers});               
  }

  public add(entidad:MetodoPago):Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);        

    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');

    return this._http
               .post(MetodoPagoService.ENDPOINT.replace(':id', ''), entidad, {headers});
  }

  public remove(_id: number): Observable<any> {
    let id= _id.toString();
    return this._http
               .delete(MetodoPagoService.ENDPOINT.replace(':id', id));
  }

}
