'use strict';

export class Proveedor {
    public Id: string;
    public PaisID: string;
    public OficinaID: string;
    public ProveedorID: string;
    public Nombre: string;
    public eMail: string;
    public ModificadoPor: string;
    public FechaModificado: Date;
    public FechaCreado: Date;
    public CreadoPor: string;
    public CategoriaID: string;
    public eMailOrdenCompra: string;
    public eMailOrdenCompra2: string;
    public Lenguaje: string;
}
