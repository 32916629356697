import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { Requisitos } from './class-requisito';


@Injectable()
export class RequisitosService {
  static ENDPOINT: string = '/api/requisitos/:id';
  constructor(@Inject(HttpClient) private _http: HttpClient) {
  }

  public getAll(): Observable<any> {
    return this._http
               .get(RequisitosService.ENDPOINT.replace(':id', ''));
  }

  public getByPaisId(id: string): Observable<any> {

    return this._http
               .get(RequisitosService.ENDPOINT.replace(':id', id));
  }

  public getByPaisIdCategoriaId(paisId: string, categoriaId: string): Observable<any> {

    return this._http
               .get(RequisitosService.ENDPOINT.replace(':id',  `${paisId}/${categoriaId}`));
  }

 public getByPaisIdCategoriaIdRequisitoId( paisId: string, categoriaId: string, maestroRequisitosId: string): Observable<any> {
    return this._http
               .get(RequisitosService.ENDPOINT.replace(':id', `${paisId}/${categoriaId}/${maestroRequisitosId}`));
  }

  public add(entidad: Requisitos): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this._http
               .post(RequisitosService.ENDPOINT.replace(':id', ''), entidad , {headers});
  }

  public update(entidad: Requisitos): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let _id: string = entidad.Id.toString();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this._http
               .put(RequisitosService.ENDPOINT.replace(':id', _id), entidad, {headers});
  }

  public getById( id: string): Observable<any> {

    return this._http
               .get(RequisitosService.ENDPOINT.replace(':id', `${id}`));
  }
}
