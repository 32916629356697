import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { Rol } from './class-rol';

// import 'rxjs/add/operator/map';

@Injectable()
export class RolService {
  static ENDPOINT: string = '/api/roles/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }

  public getAll(): Observable<any> {
   
    return this._http
               .get(RolService.ENDPOINT.replace(':id', ''));
  }    

  public getById(id: string): Observable<any> {
    return this._http
               .get(RolService.ENDPOINT.replace(':id', id));
  }

  public add(entidad:Rol): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this._http
               .post(RolService.ENDPOINT.replace(':id', ''), entidad, {headers});
  }

  public update(entidad:Rol): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this._http
               .put(RolService.ENDPOINT.replace(':id', entidad.Id), entidad, {headers});           
  }

  public remove(id: string): Observable<any> {
    return this._http
               .delete(RolService.ENDPOINT.replace(':id', id));
  }  
}
