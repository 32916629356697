 'use strict';
 import { Proveedor } from '../../+proveedores/+proveedor/class-proveedor';

export class RecepcionFactura {
    public Id: string;
    public PaisId: string;
    public OficinaId: string;
    public ProveedorId: string;
    public Factura: string;
    public Moneda: string;
    public Cantidad: number;
    public FechaRecepcion: Date;
    public UsuarioRecepcion: string;
    public PIN: string;
    public OrdenCompraNo: string;
    public ContratoUnicoID: string;
    public ArchivosAdjuntos: boolean;
    public UrlArchivo: string;
    public Proveedor: Proveedor;
    public Estado: string;
    public Comentario: string;
    public UsuarioModificacion: string;
    public FechaModificacion: Date;
    public CorreRec: string;
    public CorreSec: number;
}
