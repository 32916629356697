import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';

import {
  HttpClient,
} from '@angular/common/http';


@Injectable()
export class SlaHelpDeskSandLevelsService {
  static ENDPOINT: string = '/api/sla/slahelpdesksandlevels/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {
  }

  public getAll(): Observable<any> {
    return this._http
               .get(SlaHelpDeskSandLevelsService.ENDPOINT.replace(':id', ''));
  }  

  public NivelesMiembros(): Observable<any> {
    return this._http
               .get(SlaHelpDeskSandLevelsService.ENDPOINT.replace(':id', 'niveles/usuarios'));
  }
  
}
