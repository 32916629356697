import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Observable
} from 'rxjs/Observable';


import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { Categoria } from './class-categoria';
import 'rxjs/add/operator/map';

@Injectable()
export class CategoriaService {
  static ENDPOINT: string = '/api/categorias/:id';

  constructor(@Inject(HttpClient) private _http: HttpClient) {

  }

  public getAll(): Observable<any> {
    return this._http
               .get(CategoriaService.ENDPOINT.replace(':id', ''));
  }


 public getById(id: string): Observable<any> {
    return this._http
               .get(CategoriaService.ENDPOINT.replace(':id', id));
  }
  
  public update(entidad: Categoria): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    

    return this._http
               .put(CategoriaService.ENDPOINT.replace(':id', entidad.Id), entidad, {headers});
  }

  public add(entidad: Categoria): Observable<any> {
    // let _messageStringified = JSON.stringify(entidad);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this._http
               .post(CategoriaService.ENDPOINT.replace(':id', ''), entidad, {headers});
  }

  public remove(id: string): Observable<any> {
    return this._http
               .delete(CategoriaService.ENDPOINT.replace(':id', id));
  }
}
